import { ThemeProvider as TP } from "styled-components"
import React, { useState, useEffect } from "react"
import { defaultTheme, darkTheme } from "theme"

export const DarkModeContext = React.createContext({
  darkModeOn: false,
  setDarkMode: () => {},
})

const ThemeProvider = ({ children }) => {
  const [darkModeOn, setDarkMode] = useState(false)

  useEffect(() => {
    if (typeof window != "undefined") {
      setDarkMode(
        window.matchMedia &&
          window.matchMedia("(prefers-color-scheme: dark)").matches
      )
    }
  }, [])

  return (
    <DarkModeContext.Provider value={{ darkModeOn, setDarkMode }}>
      <TP theme={darkModeOn ? darkTheme : defaultTheme}>{children}</TP>
    </DarkModeContext.Provider>
  )
}

// function useMedia(queries, values, defaultValue) {
//   // Array containing a media query list for each query
//   let mediaQueryLists = []
//   if (typeof window != "undefined") {
//     mediaQueryLists = queries.map(q => window.matchMedia(q))
//   }
//   // Function that gets value based on matching media query
//   const getValue = () => {
//     // Get index of first media query that matches
//     const index = mediaQueryLists.findIndex(mql => mql.matches)
//     // Return related value or defaultValue if none
//     return typeof values[index] !== "undefined" ? values[index] : defaultValue
//   }
//
//   // State and setter for matched value
//   const [value, setValue] = useState(getValue)
//
//   useEffect(
//     () => {
//       // Event listener callback
//       // Note: By defining getValue outside of useEffect we ensure that it has ...
//       // ... current values of hook args (as this hook callback is created once on mount).
//       const handler = () => setValue(getValue)
//       // Set a listener for each media query with above handler as callback.
//       mediaQueryLists.forEach(mql => mql.addListener(handler))
//       // Remove listeners on cleanup
//       return () => mediaQueryLists.forEach(mql => mql.removeListener(handler))
//     },
//     [] // Empty array ensures effect is only run on mount and unmount
//   )
//   return value
// }

export default ThemeProvider

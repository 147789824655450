const baseTheme = {
  space: ["0px", "4px", "8px", "16px", "32px", "64px", "96px"],
  sizes: ["0px", "16px", "32px", "64px", "96px", "128px", "256px", "512px"],
  borderWidths: ["0px", "1px", "2px", "4px", "8px"],
  radii: ["0px", "2px", "4px", "8px"],
  breakpoints: ["576px", "768px", "992px", "1200px"],
}

export const defaultTheme = () =>
  Object.assign(
    {
      INVERT: 0,
      colors: {
        background: "#ffffff",
        primary: "#ffbd70",
        text: "#333333",
        cardText: "#808080",
        cardBackground: "#f5f5f5",
        divider: "#ebebeb",
        headerBackground: "#FFFFFF",
      },
    },
    baseTheme
  )

export const darkTheme = () =>
  Object.assign(
    {
      INVERT: 1,
      colors: {
        background: "#080808",
        primary: "#E6AA65",
        text: "#eee",
        cardText: "#ccc",
        cardBackground: "#333",
        divider: "#2b2b2b",
        headerBackground: "#222",
      },
    },
    baseTheme
  )
